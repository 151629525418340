import {useState, useEffect} from 'react'
import Image from 'next/image'

const HeroSection = ({ rawHtmlContent, mainHeroImageData, socialsBuilderObject }) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setIsMobile(window.innerWidth <= 768);
  }, []);
  return (
    <section style={{ minHeight: '100vh' }} className="hero-area pt-185  rel z-1">
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div style={{  position: 'relative' }} className="hero-content">
            <h1
              dangerouslySetInnerHTML={{ __html: rawHtmlContent.html }}
            ></h1>
            {/* <Image width={300} height={300} className='globe-hero-image' src={t_hero_image_d.image} alt={t_hero_image_d.alt} /> */}
          </div>
        </div>
      </div>
    </div>
    <div className="container-fluid layout-set">
      <div className="hero-bottom-image">
        <div>
          {isMobile
           ?
           <Image style={{ 
            width: "auto",
            height: "auto",
            maxWidth: "100%",
            display: "inline",
           }} width={1438} height={958}  priority={true} src={mainHeroImageData.mobileImage} alt={mainHeroImageData.alt} />

           :
           <Image style={{ 
            width: "auto",
            height: "auto",
            maxWidth: "100%",
            display: "inline",
           }} width={1438} height={958}  src={mainHeroImageData.imageUrl} alt={mainHeroImageData.alt} />
          }

       </div>
        <div className="hero-social">
          <a aria-label='facebook' href={socialsBuilderObject.facebook.link}>
            <i className="fab fa-facebook-f" />{" "}
            <span>{socialsBuilderObject.facebook.platform}</span>
          </a>
          <a aria-label='twitter' href={socialsBuilderObject.twitter.link}>
            <i className="fab fa-twitter" />{" "}
            <span>{socialsBuilderObject.twitter.platform}</span>
          </a>
        </div>
      </div>
    </div>
    <div className="hero-bg">
    {isMobile
           ?
           <Image style={{ 
            width: "auto",
      height: "auto",
      maxWidth: "100%",
      display: "inline",
           }}
           width={400}
           height={300}
           priority={true}
            src="/assets/images/hero/hero-bg.png" alt="lines" />
           :
           
           <Image style={{ 
            width: "auto",
      height: "auto",
      maxWidth: "100%",
      display: "inline",
           }}
           width={1434}
           height={640}
           priority={true}
            src="/assets/images/hero/hero-bg.png" alt="lines" />

           }
      
    </div>
  </section>
  )
}

export default HeroSection